import Api from "@/api"
import store from "@/store"
import router from "@/router"

const LOGIN = "/auth/token/"
const RESET_PASSWORD = "/password_reset/"
const RESET_PASSWORD_CONFIRM = "/password_reset/confirm/?token=:token"
const SIGNUP = "/users/"
const ME = "/me/"
const EMPLOYEE = "/me/employee/"
const EMPLOYEES = "/employees/"
const USERSPERMISSIONS = "/users-permissions/"

export default {
    login(data) {
        return Api.http(false).post(LOGIN, data)
    },
    resetPassword(data) {
        return Api.http(false).post(RESET_PASSWORD, data)
    },
    confirmPassword(data) {
        return Api.http(false).post(
            RESET_PASSWORD_CONFIRM.replace(":token", data.token),
            data
        )
    },
    logout() {
        store.commit("portal/logout")
        router.push("/login")
    },
    getUsers(id = "") {
        if (id !== "") {
            return Api.http(true).get(`${SIGNUP + id}/`)
        }
        return Api.http(true).get(SIGNUP)
    },
    getUserPermission(id = "", params = {}) {
        if (id !== "") {
            return Api.http(true).get(`${USERSPERMISSIONS + id}/`);
        }
        return Api.http(true).get(USERSPERMISSIONS, { params });
    },
    postUsers(data) {
        return Api.http(true).post(SIGNUP, data)
    },
    putUsers(data) {
        return Api.http(true).put(`${SIGNUP + data.id}/`, data)
    },
    putUserPermission(data) {
        return Api.http(true).put(`${USERSPERMISSIONS + data.id}/`, data)
    },
    signUp(data) {
        return Api.http(false).post(SIGNUP, data)
    },
    me(data) {
        return Api.http(true).get(ME, data)
    },
    employee() {
        return Api.http(true).get(EMPLOYEE)
    },
    employees() {
        return Api.http(true).get(EMPLOYEES)
    },
    employeePut(data, multi = false) {
        if (multi) {
            return Api.http(true).put(`${EMPLOYEES + data.id}/`, multi)
        }
        return Api.http(true).put(`${EMPLOYEES + data.id}/`, data)
    },
}
